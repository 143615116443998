import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { timer } from 'rxjs';

import { ISimpleItem, ISubItem } from '@core/models/simple-item.model';
import { IContentBuilderFieldColor } from '@core/models/content-builder-fields.model';
import { ColorFieldTypesValues } from '@config/values/color-field-types.values';

@Component({
  selector: 'app-tab-list-atom',
  templateUrl: './tab-list-atom.component.html',
  styleUrls: ['./tab-list-atom.component.scss'],
})
export class TabListAtomComponent implements OnInit, OnChanges {
  @Input() items!: ISimpleItem[];
  @Input() activeItem?: ISimpleItem;
  @Input() activeSubItem?: ISubItem;
  @Input() search?: boolean;
  @Input() displayItems!: ISimpleItem[];
  @Input() isNotched?: boolean;
  @Input() displayBurgerNavigation = false;
  @Input() categoriesBackgroundColor: IContentBuilderFieldColor = {
    value: '#FFFFFF',
    type: ColorFieldTypesValues.Hex,
  };
  @Input() categoriesActiveColor: IContentBuilderFieldColor = {
    value: 'primary-600',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() categoriesInactiveColor: IContentBuilderFieldColor = {
    value: 'neutral-600',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() burgerBackgroundColor: IContentBuilderFieldColor = {
    value: 'primary-100',
    type: ColorFieldTypesValues.Palette,
  };
  @Input() burgerIconColor: IContentBuilderFieldColor = {
    value: 'primary-600',
    type: ColorFieldTypesValues.Palette,
  };

  @Output() tabChange = new EventEmitter<{
    item: ISimpleItem;
    subItem?: ISubItem;
  }>();
  @Output() searchEvent = new EventEmitter<Event>();
  @Output() isBurgerNavigationOpen = new EventEmitter<boolean>();

  @ViewChild('scrollSection') scrollSectionElement?: ElementRef;
  @ViewChild('btnMenu') btnMenu?: ElementRef;

  activeMenu = false;

  tabClick(item: ISimpleItem, subItem?: ISubItem): void {
    this.activeItem = item;
    this.activeSubItem = subItem;
    this.tabChange.emit({ item, subItem });
    this.highlightSelectedItem();
    if (this.activeMenu) {
      this.activeMenu = false;
    }
  }

  private highlightSelectedItem(): void {
    timer(1).subscribe(() => {
      const currentChild = document.getElementsByClassName('scroll-target-item')[0] as HTMLElement;
      if (this.scrollSectionElement && currentChild) {
        const scrollSection = this.scrollSectionElement.nativeElement;
        const offset = this.btnMenu?.nativeElement.offsetWidth ?? 0;
        scrollSection.scrollTo({
          left: this.displayBurgerNavigation
            ? currentChild.offsetLeft - offset
            : currentChild.offsetLeft + (currentChild.offsetWidth - scrollSection.offsetWidth) / 2,
          behavior: 'smooth',
        });
      }
    });
  }

  toggleSearch(): void {
    this.searchEvent.emit();
  }

  toggleMenu(): void {
    this.activeMenu = !this.activeMenu;
    this.isBurgerNavigationOpen.emit(this.activeMenu);
  }

  ngOnInit(): void {
    this.displayItems = this.items;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.displayItems = this.items;

    if (changes.activeItem) {
      this.handleScrollSpyChanges(changes);
    }
  }

  private handleScrollSpyChanges(changes: SimpleChanges): void {
    this.highlightSelectedItem();
    if (this.displayItems?.find(item => item.value === changes.activeItem.currentValue?.value)) {
      return;
    }

    this.activeSubItem = changes.activeItem.currentValue;
    const subNavItems = this.displayItems?.map(item =>
      item.subNavigation?.map(subNav => subNav.value),
    );
    if (this.activeSubItem?.value) {
      const parentIndex = subNavItems?.findIndex(subNavArray =>
        subNavArray?.includes(this.activeSubItem?.value!),
      );
      this.activeItem = this.displayItems?.[parentIndex];
    }
  }
}

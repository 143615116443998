<ng-container>
  <app-modal-filler-atom [index]="(modalZIndex | async) ?? 0"></app-modal-filler-atom>
  <div
    class="fixed left-0 right-0 mx-auto overflow-y-auto shadow"
    [ngClass]="
      (isMobile$ | async)
        ? 'bottom-0 max-h-85-screen max-w-screen-sm'
        : 'flex justify-center items-center w-full h-full top-0 left-0'
    "
    [style.z-index]="((modalZIndex | async) ?? 0) + 50"
  >
    <div *ngIf="!availableDistributionModes">Loading</div>

    <div
      class="bg-white p-5"
      *ngIf="availableDistributionModes"
      [ngClass]="(isMobile$ | async) ? 'rounded-t-3xl' : 'rounded-3xl min-w-448'"
      [class.pb-8]="isNotched"
      [style.z-index]="((modalZIndex | async) ?? 0) + 50"
    >
      <div *ngIf="orderErrorMessages">
        <p
          class="my-5 rounded bg-error-50 px-4 py-3 font-base text-sm text-error-700"
          *ngFor="let message of orderErrorMessages"
        >
          {{ message }}
        </p>
      </div>
      <div
        *ngIf="existingCartMessage"
        class="mx-auto mb-7 max-w-sm"
      >
        {{ existingCartMessage }}
      </div>

      <div class="mb-7">
        <!-- Distribution mode picker -->
        <div>
          <div
            *ngIf="availableDistributionModes?.length === 1"
            class="mb-4"
          >
            <app-button-atom
              [size]="'L'"
              [disabled]="true"
              [label]="availableDistributionModes[0].label | translate"
            >
            </app-button-atom>
          </div>
          <div
            *ngIf="
              (availableDistributionModes?.length ?? 0) > 1 &&
              (availableDistributionModes?.length ?? 0) < 3
            "
            class="mx-auto mb-4 max-w-sm"
          >
            <app-parameters-distribution-mode-block
              [selectedDistributionMode]="selectedDistributionMode ?? ''"
              [isCartParameters]="true"
              [items]="availableDistributionModes"
              (selectItem)="changeDistributionMode($event)"
            ></app-parameters-distribution-mode-block>
          </div>
          <div
            *ngIf="(availableDistributionModes?.length ?? 0) >= 3"
            class="mx-auto mb-4"
          >
            <app-custom-select-atom
              [items]="availableDistributionModes"
              [selectedItem]="selectedDistributionMode"
              (selectItem)="changeDistributionMode($event)"
            ></app-custom-select-atom>
          </div>
        </div>

        <!-- Message -->
        <div
          *ngIf="message"
          class="my-4 whitespace-pre-line rounded rounded-s bg-neutral-50 p-4 font-base text-sm text-neutral-700"
        >
          {{ message }}
        </div>

        <!-- Delivery address -->
        <div
          *ngIf="
            selectedOrderTypeCapabilities &&
            selectedOrderTypeCapabilities.isDeliveryAddressMandatory
          "
          class="mx-auto max-w-sm"
        >
          <app-parameters-location-block
            (placeChanged)="placeChanged($event)"
            [selectedValue]="selectedLocation?.formatted_address ?? ''"
            [selectedCommentValue]="selectedLocation?.instructions"
          ></app-parameters-location-block>
          <div
            *ngIf="!isCheckDeliveryAddressInProgress && deliveryAddressError"
            class="mx-auto max-w-sm"
          >
            <p class="mt-1 font-base text-xs leading-4 text-secondary-700">
              {{ deliveryAddressError | translate }}
            </p>
          </div>
          <div
            *ngIf="isCheckDeliveryAddressInProgress"
            class="mx-auto flex max-w-sm flex-row justify-center"
          >
            <img
              src="assets/images/spinner.svg"
              alt=""
              class="p-2"
            />
          </div>

          <div
            *ngIf="deliverableShop"
            class="mt-8"
          >
            <h2 class="mb-4 text-lg font-bold text-neutral-800">
              Un autre point de vente livre à cette addresse
            </h2>
            <div class="mb-4 flex justify-between">
              <div class="flex items-center">
                <div class="text-sm">
                  <p class="font-bold">{{ deliverableShop.name }}</p>
                  <p *ngIf="deliverableShop.venue?.address?.street_line1">
                    {{ deliverableShop.venue?.address?.street_line1 }}
                  </p>
                  <p>
                    <span *ngIf="deliverableShop.venue?.address?.post_code"
                      >{{ deliverableShop.venue?.address?.post_code }}
                    </span>
                    <span *ngIf="deliverableShop.venue?.address?.city">{{
                      deliverableShop.venue?.address?.city
                    }}</span>
                  </p>
                </div>
              </div>
              <app-button-atom
                size="S"
                [label]="'Choisir'"
                [color]="'primary'"
                [type]="'light'"
                [thickSize]="'XS'"
                (click)="goToShop(deliverableShop)"
              ></app-button-atom>
            </div>
          </div>
        </div>
      </div>
      <!-- Delivery Instructions -->
      @if (
        (selectedDistributionMode === distributionMode.Delivery ||
          selectedDistributionMode === distributionMode.Shipping) &&
        !((userIsLoggedIn$ | async) && selectedLocation?.instructions)
      ) {
        <div class="mx-auto mb-7 max-w-sm">
          <app-parameters-delivery-instructions-block
            [instructions]="forceType(deliveryComment)"
            (instructionsChanged)="deliveryCommentChanged(forceType($event))"
          ></app-parameters-delivery-instructions-block>
        </div>
      }

      <!-- Wanted at -->
      <div
        class="mx-auto mb-7 max-w-sm"
        *ngIf="selectedOrderTypeCapabilities?.preorderingAllowed"
      >
        <app-parameters-wanted-at-block
          [dateSlots]="wantedAtDateSlots"
          [timeSlots]="wantedAtTimeSlots"
          [wantedAtType]="selectedWantedAtType ?? ''"
          [wantedAtDate]="selectedWantedAtDate ?? undefined"
          [wantedAtTime]="selectedWantedAtTime ?? undefined"
          [displayAsapForLaterPicker]="displayAsapForLaterPicker"
          [distributionMode]="selectedDistributionMode ?? ''"
          (typeChanged)="onWantedAtTypeChanged($event)"
          (dateChanged)="onWantedAtDateChanged($event)"
          (timeChanged)="onWantedAtTimeChanged($event)"
        >
        </app-parameters-wanted-at-block>
      </div>

      <!-- On site location -->
      <div
        class="mb-7"
        *ngIf="
          selectedOrderTypeCapabilities && selectedOrderTypeCapabilities.isOnSiteLocationMandatory
        "
      >
        <!--            <ng-container *ngIf="onSiteLocationName">-->
        <!--                Emplacement : {{onSiteLocationName}}-->
        <!--            </ng-container>-->
        <ng-container *ngIf="activeShop$ | async as shop">
          <app-on-site-location-select
            [shop]="shop"
            [selectedOnSiteLocationId]="selectedOnSiteLocationId"
            (onSiteLocationChanged)="onOnSiteLocationChanged($event)"
          ></app-on-site-location-select>
        </ng-container>
      </div>

      <!-- Submit -->
      <div class="">
        <app-button-atom
          [iconUrl]="isSaving ? 'assets/images/spinner.svg' : ''"
          [disabled]="
            isSaving ||
            !selectedOrderTypeCapabilities ||
            (selectedOrderTypeCapabilities.isDeliveryAddressMandatory && !isDeliveryAddressValid)
          "
          [color]="'primary'"
          [label]="'parameters.validate' | translate"
          [type]="'primary'"
          [size]="'L'"
          (click)="submit()"
        >
        </app-button-atom>
      </div>
    </div>
  </div>
</ng-container>

<div
  class="shadow-xs mx-auto flex items-center justify-center text-center shadow"
  [style.backgroundColor]="categoriesBackgroundColor | contentBuilderColor"
>
  <div #btnMenu>
    <app-rounded-icon-button
      *ngIf="!activeMenu && displayBurgerNavigation"
      class="px-5 lg:pl-12"
      [outlined]="true"
      [icon]="'icon-hamburger font-bold'"
      [size]="'sm'"
      [fontSize]="'text-xs'"
      [overrideBackgroundColor]="burgerBackgroundColor"
      [overrideColor]="burgerIconColor"
      (click)="toggleMenu()"
    ></app-rounded-icon-button>
  </div>

  <div
    class="sticky-tab-list w-full overflow-x-auto bg-white pr-4 scrollbar-hide"
    [class.pt-10]="isNotched"
    [class.shadow-none]="isNotched"
    [class.border-b]="isNotched"
    [ngClass]="{
      'block bg-white fixed top-0 left-0 z-120 h-screen w-screen py-3': activeMenu,
      'flex items-center': !activeMenu,
      'px-5 lg:px-12': !displayBurgerNavigation,
      'lg:pr-12': displayBurgerNavigation,
    }"
    #scrollSection
  >
    <div
      *ngIf="activeMenu || search"
      class="w-full text-left"
      [ngClass]="{
        'px-5 lg:px-12': activeMenu,
      }"
    >
      <app-rounded-icon-button
        *ngIf="activeMenu"
        class="mr-4"
        [outlined]="true"
        [icon]="'icon-cross font-bold'"
        [size]="'sm'"
        [fontSize]="'text-xs'"
        [overrideBackgroundColor]="burgerBackgroundColor"
        [overrideColor]="burgerIconColor"
        (click)="toggleMenu()"
      ></app-rounded-icon-button>
      <app-rounded-icon-button
        *ngIf="search"
        class="mr-4"
        [outlined]="true"
        [icon]="'icon-zoom font-bold'"
        [size]="'sm'"
        [fontSize]="'text-base'"
        [overrideBackgroundColor]="burgerBackgroundColor"
        [overrideColor]="burgerIconColor"
        (click)="toggleSearch()"
      ></app-rounded-icon-button>
    </div>
    <div class="flex w-full items-center justify-between border-neutral-300">
      <div
        [ngClass]="{
          'block px-5 lg:px-12': activeMenu,
          'flex items-center': !activeMenu,
        }"
      >
        <button
          class="relative mr-3 flex items-center whitespace-nowrap border-b-4 py-3 font-accent font-semibold lg:py-5"
          [class.scroll-target-item]="activeItem === item"
          [class.px-2]="!activeMenu"
          [style.borderColor]="
            activeItem === item && !activeMenu
              ? (categoriesActiveColor | contentBuilderColor)
              : 'transparent'
          "
          [style.color]="
            activeItem === item
              ? (categoriesActiveColor | contentBuilderColor)
              : (categoriesInactiveColor | contentBuilderColor)
          "
          *ngFor="let item of displayItems"
          (click)="tabClick(item)"
        >
          {{ item.label }}
          <span
            *ngIf="item.icon"
            class="text-8px transform rotate-180 icon icon-{{ item.icon }} ml-2"
          ></span>
        </button>
      </div>
    </div>
  </div>
</div>
